import { object, string, ref } from 'yup'

const emailSchema = object({
  email: string().required().trim().email('Invalid email address!').typeError('Field cannot be empty!').label('Email')
})

export const passwordSchema = object({
  password: string().required().trim().min(6).max(64).typeError('Field cannot be empty!').label('Password')
})

export const loginEmailSchema = object({
  ...emailSchema.fields,
  ...passwordSchema.fields
})

export const forgotPasswordEmailSchema = object({
  ...emailSchema.fields
})

export const resetPasswordSchema = object({
  password: string().required().trim().min(6).max(64).typeError('Field cannot be empty!').label('New password'),
  confirmPassword: string().required().trim().oneOf([ref('password')], 'Passwords not matches!').typeError('Field cannot be empty!').label('Confirm new password')
})
