import { object, string, ref } from 'yup'

export const profileChangePasswordSchema = object({
  password: string().required().trim().min(6).max(64).typeError('Field cannot be empty!').label('Old password'),
  newPassword: string().required().trim().min(6).max(64).typeError('Field cannot be empty!').label('New password'),
  confirmNewPassword: string().required().trim().typeError('Field cannot be empty!').oneOf([ref('newPassword')], 'Passwords not matches!').label('Confirm new password')
})

export const profileUpdateSchema = object({
  email: string().required().trim().email('Invalid email address!').typeError('Email or phone must be entered').label('Email'),
  firstName: string().required().trim().min(1).typeError('Field cannot be empty!').label('First name'),
  lastName: string().required().trim().min(1).typeError('Field cannot be empty!').label('Last name')
})
