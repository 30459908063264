import { object, string } from 'yup'

export const postUpdateAndPublishSchema = object({
  title: string().required().trim().min(1).typeError('Field cannot be empty!').label('Title'),
  thumbnailUrl: string().required().trim().url().typeError('Field cannot be empty!').label('Thumbnail URL'),
  content: string().required().trim().min(1).typeError('Field cannot be empty!').label('Content'),

  telegram: string().required().trim().min(1).typeError('Field cannot be empty!').label('Telegram'),
  twitter: string().required().trim().min(1).max(250).typeError('Field cannot be empty!').label('Twitter'),
  instagram: string().required().trim().min(1).typeError('Field cannot be empty!').label('Instagram')
})

export const postCreateAndPublishSchema = object({
  title: string().required().trim().min(1).typeError('Field cannot be empty!').label('Title'),
  content: string().required().trim().min(1).typeError('Field cannot be empty!').label('Content'),

  telegram: string().required().trim().min(1).typeError('Field cannot be empty!').label('Telegram'),
  twitter: string().required().trim().min(1).max(250).typeError('Field cannot be empty!').label('Twitter'),
  instagram: string().required().trim().min(1).typeError('Field cannot be empty!').label('Instagram')
})
