import { object, string } from 'yup'

export const resourceCreateUpdateSchema = object({
  name: string().required().trim().min(1).typeError('Field cannot be empty!').label('Name'),
  domain: string().required().trim().url().matches(/^\S*$/, { message: 'Invalid Domain' }).typeError('Field cannot be empty!').label('Domain'),
  titleField: string().required().trim().min(1).matches(/^\S*$/, { message: 'Invalid Title field' }).typeError('Field cannot be empty!').label('Title field'),
  thumbnailUrlField: string().required().trim().min(1).matches(/^\S*$/, { message: 'Invalid Thumbnail URL field' }).typeError('Field cannot be empty!').label('Thumbnail URL field'),
  contentField: string().required().trim().min(1).matches(/^\S*$/, { message: 'Invalid Content field' }).typeError('Field cannot be empty!').label('Content field'),
  guidField: string().required().trim().min(1).matches(/^\S*$/, { message: 'Invalid Content field' }).typeError('Field cannot be empty!').label('Content field')
})
