<template>
  <div
    class="relative pb-24"
    :class="containerClass"
  >
    <label
      v-if="label"
      :for="id"
      class="flex items-center text-14 font-medium mb-8 text-blueGray-500 text-left"
    >
      {{ label }}

      <ElTooltip
        v-if="required"
        placement="top"
        effect="light"
      >
        <template #content>Required field!</template>
        <div class="cursor-pointer ml-4">*</div>
      </ElTooltip>

      <div
        v-if="$slots.infoText"
        class="flex ml-8"
      >
        <ElTooltip
          placement="top"
          effect="light"
        >
          <template #content>
            <div class="w-full max-w-64 text-center leading-tight">
              <slot name="infoText" />
            </div>
          </template>
          <i class="icon-info text-base text-blueGray-500 cursor-pointer" />
        </ElTooltip>
      </div>
    </label>

    <div class="flex relative">
      <!-- Icon left -->
      <div
        v-if="$slots.iconLeft"
        class="absolute left-0 top-0 bottom-0 h-full flex justify-center items-center pl-16 w-40"
      >
        <slot name="iconLeft" />
      </div>

      <component
        :is="componentType"
        :id="id"
        autocomplete="off"
        :value="modelValueTyped"
        v-bind="$attrs"
        :disabled="disabled"
        :type="inputType"
        :readonly="readonly"
        :class="[
          'app-input',
          ...inputClass,
          {
            'is-error': validationErrorMessage,
            'is-disabled': disabled,
            'is-password': isPassword,
            'hover:border-blue-300': !disabled,
            'icon-left': $slots.iconLeft,
            'icon-right': $slots.iconRight,
            'resize-y min-h-230 py-12': textarea
          }
        ]"
        :style="{
          ...textarea ? { lineHeight: 1.5 } : null
        }"
        @input="changeValue"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />

      <!-- Password icon -->
      <div
        v-if="isPassword"
        class="absolute right-0 top-0 bottom-0 h-full flex justify-center items-center pr-16 w-40"
      >
        <div
          tabindex="-1"
          class="w-full flex items-center justify-center cursor-pointer"
          @click.prevent="toggleType"
        >
          <i
            class="icon-eye text-24 text-gray-400 transition duration-200 hover:text-blue-600"
            :class="{'text-blue-600': inputType === 'text'}"
          />
        </div>
      </div>

      <!-- Icon right -->
      <div
        v-if="$slots.iconRight && !isPassword"
        class="absolute right-0 top-0 bottom-0 h-full flex justify-center items-center pr-16 w-40"
      >
        <slot name="iconRight" />
      </div>
    </div>

    <ElTooltip
      v-if="validationErrorMessage"
      placement="top"
      effect="light"
      popper-class="inputs-validation-error"
    >
      <template #content>
        <div class="w-full max-w-256 leading-tight text-center">
          {{ validationErrorMessage }}
        </div>
      </template>
      <p
        class="w-auto max-w-full h-24 absolute text-12 left-0 bottom-0 text-left
        text-red-500 truncate leading-none pt-1 cursor-pointer"
      >
        {{ validationErrorMessage }}
      </p>
    </ElTooltip>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue'

import { useField } from 'vee-validate'
import { ElTooltip } from 'element-plus'

import { localeNumber } from '@/core/localisation'

const EInputSize = {
  small: 'app-input--sm',
  default: 'app-input--default',
  large: 'app-input--lg'
}

export default defineComponent({
  name: 'AppInput',

  components: { ElTooltip },

  inheritAttrs: false,

  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    modelValue: {
      type: [String, Number, null]
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    textarea: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    decimals: {
      type: Boolean,
      default: false
    },
    currency: {
      type: [String, null],
      default: null
    }
  },

  emits: ['update:modelValue'],

  setup (props, { emit, attrs }) {
    // Validation
    const {
      errorMessage: validationErrorMessage,
      handleChange
    } = useField(attrs.name, undefined, {
      initialValue: props.modelValue
    })

    const numberInputTypes = ['currency', 'numbers']

    const inputClass = [EInputSize[props.size]]
    const inputType = ref(attrs.type)
    const isFocused = ref(false)

    const componentType = computed(() => {
      return props.textarea ? 'textarea' : 'input'
    })

    const isPassword = computed(() => {
      return attrs.type === 'password'
    })

    const numberModelValue = computed(() => {
      if (isFocused.value) {
        return props.modelValue
      }

      if (typeof props.modelValue !== 'number') {
        return null
      }

      return localeNumber(
        props.modelValue,
        props.decimals,
        props.currency
      )
    })

    const modelValueTyped = computed(() => {
      return numberInputTypes.includes(inputType.value) ? numberModelValue.value : props.modelValue
    })

    watch(() => props.modelValue, (modelValue) => {
      handleChange(modelValue)
    })

    const changeValue = (e) => {
      if (inputType.value === 'phone' && e.target.value?.length !== 0 && !e.target?.value?.startsWith('+')) {
        e.target.value = `+${e.target.value}`
      }

      // Numbers/Currency handler
      if (numberInputTypes.includes(inputType.value)) {
        currencyHandleChange(e.target.value)
        return
      }

      const value = e.target.value?.length ? e.target.value : null
      emit('update:modelValue', value)
    }

    const currencyHandleChange = (value) => {
      const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null

      emit('update:modelValue', parsedValue)
    }

    const toggleType = () => {
      inputType.value === 'text' ? inputType.value = 'password' : inputType.value = 'text'
    }

    return {
      inputClass,
      inputType,
      componentType,
      isPassword,
      toggleType,
      modelValueTyped,
      isFocused,
      validationErrorMessage,
      changeValue
    }
  }
})
</script>

<style lang="scss" scoped>
.app-input {
  @apply placeholder-blueGray-400 font-medium leading-none
      bg-blueGray-50 outline-none focus:outline-none rounded-4 border border-blue-200 focus:ring-1
      focus:ring-indigo-500 focus:border-indigo-500 transition duration-200;

  &.is-disabled {
    @apply cursor-not-allowed opacity-50;
  }
  &.is-error {
    @apply border-red-500;
  }

  &--sm {
    @apply w-full h-30 text-14 pl-16 pr-16;

    &.icon-left {
      @apply pl-12;
    }
    &.icon-right,
    &.is-password {
      @apply pr-12;
    }
  }

  &--default {
    @apply w-full h-40 text-14 pl-16 pr-16;

    &.icon-left {
      @apply pl-12;
    }
    &.icon-right,
    &.is-password {
      @apply pr-12;
    }
  }

  &--lg {
    @apply w-full h-40 text-14 pl-16 pr-16;

    &.icon-left {
      @apply pl-12;
    }
    &.icon-right,
    &.is-password {
      @apply pr-12;
    }
  }
}
</style>
